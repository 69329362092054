.awareness-main-bg {
  background-image: url("../Assets/awr-main-bg.webp");
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0,0,0,.8)!important;
}
.awareness-main-bg .main__content {
  width: 100%;
  max-width: 400px;
  min-height: 100vh;
    margin: 0 auto;
}
.awareness-main-bg .main__content img{
  min-height: 100vh;
}
